import 'jquery-validation'

/*
 *
 * ----------------------------------------------- */
// jQuery Validation Plugin
// https://jqueryvalidation.org/
document.addEventListener('DOMContentLoaded', () => {
  const button = document.getElementById('submitButton')
  const loadingIcon = document.getElementById('loadingIcon')

  // Initialize jQuery validation for the form
  jQuery(function ($) {
    const form = $('.form-contact') // Select the form element using jQuery

    form.validate({
      submitHandler: function () {
        // Show the loading icon
        loadingIcon.style.opacity = '1'

        // Simulate a delay (e.g., waiting for a response)
        setTimeout(() => {
          // Hide the loading icon after 3 seconds
          loadingIcon.style.opacity = '0'

          // Submit the form
          form[0].submit() // Use native form submission
        }, 3000) // 3 seconds
      }
    })

    // Button click handler
    button.addEventListener('click', (event) => {
      event.preventDefault() // Prevent default submission

      // Trigger form validation
      if (form.valid()) {
        form.submit() // Trigger the submitHandler if valid
      }
    })
  })
})

/*
 *
 * ----------------------------------------------- */
jQuery.extend(jQuery.validator.messages, {
  required: 'Vui lòng nhập thông tin',
  remote: 'Hãy chỉnh sửa trường này',
  email: 'Vui lòng nhập địa chỉ email hợp lệ',
  url: 'Vui lòng nhập URL hợp lệ',
  date: 'Vui lòng nhập ngày hợp lệ',
  dateISO: 'Vui lòng nhập ngày hợp lệ (ISO)',
  number: 'Vui lòng nhập một số hợp lệ',
  digits: 'Chỉ được nhập các chữ số',
  creditcard: 'Vui lòng nhập số thẻ tín dụng hợp lệ',
  equalTo: 'Vui lòng nhập cùng một giá trị lần nữa',
  extension: 'Vui lòng nhập giá trị có phần mở rộng hợp lệ',
  maxlength: jQuery.validator.format('Vui lòng nhập không quá {0} ký tự'),
  minlength: jQuery.validator.format('Vui lòng nhập ít nhất {0} ký tự'),
  rangelength: jQuery.validator.format(
    'Vui lòng nhập giá trị từ {0} đến {1} ký tự'
  ),
  range: jQuery.validator.format('Vui lòng nhập giá trị từ {0} đến {1}'),
  step: jQuery.validator.format('Vui lòng nhập một bội số của {0}'),
  max: jQuery.validator.format('Vui lòng nhập giá trị nhỏ hơn hoặc bằng {0}'),
  min: jQuery.validator.format('Vui lòng nhập giá trị lớn hơn hoặc bằng {0}')
})

/*
 *
 * ----------------------------------------------- */
jQuery.validator.addMethod(
  'custom-email',
  function (value, element) {
    var emailArray = value.split('@')
    // preg_match("/^[\.!#%&\-_0-9a-zA-Z\?\/\+]+\@[!#%&\-_0-9a-z]+(\.[!#%&\-_0-9a-z]+)+$/", "$str") && count($mailaddress_array) ==2

    return (
      this.optional(element) ||
      (/^[.!#%&\-_0-9a-zA-Z?/+]+@[!#%&\-_0-9a-z]+(\.[!#%&\-_0-9a-z]+)+$/.test(
        value
      ) &&
        emailArray.length === 2)
    )
  },
  '正しいメールアドレスを入力して下さい。'
)
