import { isMobile } from "./isMobile";

/*
 * Fixed position header
 * ----------------------------------------------- */
jQuery(window).on("load scroll", function () {
  var $jsHeader = $(".js-header");

  $jsHeader.each(function () {
    var scroll = $(window).scrollTop();

    if (scroll > 80) {
      $jsHeader.addClass("is-scrolled");
    } else {
      $jsHeader.removeClass("is-scrolled");
    }
  });
});

/*
 * Close navbar
 * ----------------------------------------------- */
var $menuTrigger = $(".menu-trigger");

$(".navbar .nav-link").on("click", function () {
  $menuTrigger[0].click();
});
