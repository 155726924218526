// MEMO: import this for IE11.
// MEMO: Swiper 5.4.5 works on IE11.
// import Swiper from "../../node_modules/swiper/js/swiper";

// import the latest version
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

/*
 *
 * ----------------------------------------------- */
const swipers = {};

// Function to initialize a swiper
function initSwiper(swiperEl) {
  const swiperId = swiperEl.getAttribute("data-swiper-id");
  const paginationClass = swiperEl.getAttribute("data-pagination");

  // Count the number of slides in the Swiper
  const slidesCount = swiperEl.querySelectorAll(".swiper-slide").length;

  // Set loop to false if there is only 1 slide
  const shouldLoop = slidesCount > 1;

  if (!swipers[swiperId]) {
    // Initialize the swiper for the first time
    swipers[swiperId] = new Swiper(swiperEl, {
      slidesPerView: 1,
      spaceBetween: 25,
      duration: 2500,
      loop: shouldLoop,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      pagination: {
        el: paginationClass,
        clickable: true,
      },
    });
  } else {
    // Update swiper if it's already initialized
    swipers[swiperId].update();
  }
}

// Initialize Swiper when a tab is shown
document
  .querySelectorAll('button[data-bs-toggle="tab"]')
  .forEach(function (tab) {
    tab.addEventListener("shown.bs.tab", function (event) {
      const targetPane = event.target.getAttribute("data-bs-target");
      const swiperEl = document.querySelector(targetPane + " .swiper-init");

      if (swiperEl) {
        initSwiper(swiperEl);
        $("[data-mh]").matchHeight({ remove: true });
        $("[data-mh]").matchHeight();
        $.fn.matchHeight._maintainScroll = true;
        $.fn.matchHeight._applyDataApi();
      }
    });
  });

// Initialize Swiper for the first tab on page load
document.addEventListener("DOMContentLoaded", function () {
  const activeTabContent = document.querySelector(
    ".tab-pane.active .swiper-init"
  );
  if (activeTabContent) {
    initSwiper(activeTabContent); // Manually initialize the Swiper for the first visible tab
  }
});

/*
 *
 * ----------------------------------------------- */
var swiperNonstop = document.querySelector(".swiper-nonstop-slide");

if (swiperNonstop) {
  new Swiper(swiperNonstop, {
    observer: true,
    observeParents: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 25,
    initialSlide: 2,
    loop: true,
    speed: 7500,
    allowTouchMove: false,
    autoplay: {
      enabled: true,
      delay: 1,
    },
    breakpoints: {
      768: {
        spaceBetween: 30,
      },
    },
  });
}
