import "jquery-match-height";

/*
 *
 * ----------------------------------------------- */
jQuery(window).on("load", function (e) {
  $("[data-mh-window-load]").matchHeight({
    // byRow: true,
    property: "min-height",
    // target: null,
    // remove: false
  });
});

/*
 * For nested child elements
 * ----------------------------------------------- */
jQuery(window).on("load", function (e) {
  $(".row").each(function () {
    $(this).find(".nested").matchHeight({
      // byRow: true,
      property: "min-height",
      // target: null,
      // remove: false
    });
  });
});

$('[data-bs-toggle="tab"]').on("shown.bs.tab", function () {
  console.log("Tab is fully visible, applying matchHeight");
  $("[data-mh]").matchHeight({ remove: true });
  $("[data-mh]").matchHeight();
  $.fn.matchHeight._maintainScroll = true;
  $.fn.matchHeight._applyDataApi();
});
